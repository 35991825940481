import PageHeader from '@/components/PageHeader.vue'
import $userRequest from '@/request/contactsapi.js'
import $contractreq from '@/request/contractApi.js'
import contractFilsList from '@/views/contract/contractFilsList.vue'
import tableMixin from '@/minxin/table-minxin'
import root from '@/minxin/root'
const contractMaterialList = {
  name: 'contractMaterialList',
  components: {
    PageHeader,
    contractFilsList
  },
  mixins: [tableMixin, root],
  data() {
    return {
      root_name: 'wlhyqd',
      root_msg: '物料合约清单',
      contractTypevisible: false,
      typestyle: 'cursor p-bottom-5',
      typecolor: 'cursor typecolor p-bottom-5',
      contractType: 1, // 合约类型  1： 销售 2 采购
      // 合约列表
      tableData: [
        {
          IDX: 1,
          ContactID: '312312', // 合约编号
          InvCode: '312312', // 物料编号
          ManuPartNum: '312312', // 原厂编号
          MaterialName: '312312', // 物料名称
          MaterialSpefication: '312312', // 规格
          MaterialBrandName: '312312', // 品牌
          MaterialTypeName: '312312', // 物料类型
          MaterialSubTypeName: '312312', // 物料类型
          StockUnitN: '312312', // 单位
          // Deadline: '312312', // 效期
          LatestAmount: '312312', // 价格
          StatusString: '已启用',
          Status: 1,
          RegisterNo: '312312', // 注册证号
          Tax: '312312', // 税率
          ContractType: '312312', // 合约类型 0-销售合约；1-采购合约
          ContractName: 'fsadfsadfs',
          BuyCompanyName: 'fdsaf',
          SellCompanyName: 'fasfdsf',
          SendCompanyName: 'fasfdsf',
          CreateUserName: 'ffdsa',
          Deadline: '2021-09-12',
          WriteTime: '2020-02-03',
          isEdit: false
        }
      ],
      tableHeight: 'calc(100vh - 340px)',
      tableDataloading: false,
      tableTotalSize: 0,
      // // 物料高级搜索
      listadvancematerialParas: {
        IsAdvancedSearch: 0,
        KeyWords: '',
        CreateStartTime: '',
        CreateEndTime: '',
        ContractType: null,
        ContractCode: '', // 合约编号
        ContractName: '', // 合约名称
        InvCode: '', // 物料编号
        InvName: '', // 物料名称
        brandname: '', // 品牌
        BuyCompanyID: null, // 买方
        SellCompanyID: null, // 供应商
        SendCompanyID: null, // 终端
        CertificateName: '', // 注册证号
        PageIndex: 1,
        PageSize: 20
      },
      // 合约高级搜索
      AdvancedsearchVisibel: false,
      // 状态筛选
      statusList: [
        {
          lable: '已启用',
          value: 1
        },
        {
          lable: '已禁用',
          value: 2
        },
        {
          lable: '待审核',
          value: 3
        },
        {
          lable: '已驳回',
          value: 4
        }
      ],
      // 导出
      formSearch: {
        Type: 1, // 1 销售合约 2 采购合约
        KeyWords: '', // 关键字
        PageIndex: 1,
        PageSize: -1, // 默认传-1
        Idxs: '', // 如果有 选中传入合约明细id 逗号分隔
        FieldStr: '' // 自定义字段json
      },
      selects: [],
      // 导出
      // 改价
      updatepriceVisibel: false,
      updatepriceInfo: {
        ContactID: 35743, // 合约id
        IdxList: '',
        EditType: 1, // 1: 按比例 2：固定
        value: null,
        precent: 30,
        price: null
      }, // 改价
      // ================================================= 选择人员 begin  =================================================
      // ================================================= 选择人员 begin ==================================================
      fullscreenLoading: false,
      seletUserVisible: false,
      selectUseractiveName: 'user', // 选择人员tab切换
      isshow: false, // 控制 部门和角色选择是否显示
      selectUserkeywords: '', // 搜索成员关键字
      connuserlist: [], // 成员list
      selectUserDeptData: [], // 部门数据
      selectUserroleData: [], // 角色数据
      checkedUserInfo: null, // 选中的人员对象
      checkedContractOptionUserID: null, // 选中人员的id
      checkedContractOptionUserName: null, // 选中人员的name
      checkedContractOptionUserNickName: null, // 选中人员的NickName
      // ================================================= 选择人员 end  =================================================
      // ================================================= 选择人员 end ==================================================
      checkedMaterial: [], // 选中的物料明细
      _contractID: null,
      // 选择甲方
      selectBuyloading: false,
      // 选择供应商
      selectsellloading: false,
      // 选择终端
      selectsendloading: false,
      allbuyCompanyList: [],
      allSaleCompanyList: [],
      allCustomerCompanyList: [],
      // 获取公司列表参数
      sellCompanyListParas: {
        CompanyType: null, //  0、客户；1、供应商
        CompanyName: '', // 关键字
        pageIndex: 1,
        pageSize: -1
      },
      // 买方list
      BuyCompanyList: [
      ],
      // 供应商list
      SaleCompanyList: [
      ],
      // 终端list
      CustomerCompanyList: [
      ]
    }
  }, // data ends
  watch: {
    selectUserkeywords(val) {
      this.$refs.allusertree.filter(val)
    }
  },
  mounted() {
    // 获取按钮权限
    // this.getBottons('wlhyqd')
    this.getContractMaterialList()
    this.getSellCompanyList()
    this.getBuyCompanyListfromdata()
  },
  methods: {
    // 导出
    exportExel() {
      const _this = this
      console.log('this.checkedMaterial', this.checkedMaterial)
      _this.tableDataloading = true
      this.formSearch.Type = this.contractType
      this.formSearch.FieldStr = JSON.stringify(this.tableListJson[ this.$refs.FieldManagement.name ])
      this.formSearch.Idxs = this.checkedMaterial.join(',')
      this.formSearch.KeyWords = this.listadvancematerialParas.KeyWords
      this.$refs.ExportLoading.open()
      $contractreq.GetContractMaterialList_Export(_this.formSearch).then((res) => {
        _this.tableDataloading = false
        if (res.RetCode === '0') {
          // _this.downloadFile(res.Message, _this.guid() + '.xlsx')
          const name = _this.$minCommon.getExportName('合约物料清单')
          _this.$refs.ExportLoading.success(res.Message, name)
          return _this.$minCommon.downloadFile(res.Message, name)
        } else {
          _this.$refs.ExportLoading.close()
          return _this.$message.error('导出数据出错：' + res.RetMsg)
        }
      })
    },
    // 获取页面按钮权限
    // async getBottons(elementId) {
    //   const _this = this
    //   await this.$jurisdiction.GetUserButtonListByMenuElementID({ elementId: elementId }).then(res => {
    //     if (res.RetCode === '0') {
    //       _this.userBottons = res.Message
    //     } else {
    //       _this.$message.error('加载页面出错： ' + res.Message)
    //       console.log('物料合约清单列表获取按钮权限报错：' + res.RetMsg)
    //     }
    //   })
    // },
    // 清空高级搜索字段
    initadvance() {
      this.listadvancematerialParas = {
        IsAdvancedSearch: 0,
        KeyWords: '',
        CreateStartTime: '',
        CreateEndTime: '',
        ContractType: null,
        ContractCode: '', // 合约编号
        ContractName: '', // 合约名称
        InvCode: '', // 物料编号
        InvName: '', // 物料名称
        brandname: '', // 品牌
        BuyCompanyID: null, // 买方
        SellCompanyID: null, // 供应商
        SendCompanyID: null, // 终端
        CertificateName: '', // 注册证号
        PageIndex: 1,
        PageSize: 20
      }
    },
    // 终端选择
    sendremoteMethod(query) {
      this.selectsendloading = true
      this.CustomerCompanyList = []
      if (query && query.length > 0) {
        this.allCustomerCompanyList.forEach((item) => {
          if (this.CustomerCompanyList && this.CustomerCompanyList.length < 21) {
            if (item.text) {
              if (item.text.indexOf(query) > -1) {
                this.CustomerCompanyList.push(item)
              }
            }
          }
        })
      } else {
        if (this.allCustomerCompanyList.length > 5) {
          for (let i = 0; i < 5; i++) {
            this.CustomerCompanyList.push(this.allCustomerCompanyList[i])
          }
        } else {
          this.CustomerCompanyList = this.allCustomerCompanyList
        }
      }
      this.selectsendloading = false
    },
    // 供应商选择
    sellremoteMethod(query) {
      this.selectsellloading = true
      this.SaleCompanyList = []
      if (query && query.length > 0) {
        this.allSaleCompanyList.forEach((item) => {
          if (this.SaleCompanyList && this.SaleCompanyList.length < 21) {
            if (item.text) {
              if (item.text.indexOf(query) > -1) {
                this.SaleCompanyList.push(item)
              }
            }
          }
        })
      } else {
        if (this.allSaleCompanyList.length > 5) {
          for (let i = 0; i < 5; i++) {
            this.SaleCompanyList.push(this.allSaleCompanyList[i])
          }
        } else {
          this.SaleCompanyList = this.allSaleCompanyList
        }
      }
      this.selectsellloading = false
    },
    // 甲方选择
    buyremoteMethod(query) {
      this.selectBuyloading = true
      this.BuyCompanyList = []
      if (query && query.length > 0) {
        this.allbuyCompanyList.forEach((item) => {
          if (this.BuyCompanyList && this.BuyCompanyList.length < 21) {
            if (item.text) {
              if (item.text.indexOf(query) > -1) {
                this.BuyCompanyList.push(item)
              }
            }
          }
        })
      } else {
        if (this.allbuyCompanyList.length > 5) {
          for (let i = 0; i < 5; i++) {
            this.BuyCompanyList.push(this.allbuyCompanyList[i])
          }
        } else {
          this.BuyCompanyList = this.allbuyCompanyList
        }
      }
      this.selectBuyloading = false
    },
    // 获取供应商数据
    getSellCompanyList() {
      const _this = this
      _this.sellCompanyListParas.CompanyType = 1
      $contractreq.GetSellCompanyList(_this.sellCompanyListParas).then(res => {
        if (res.RetCode === '0') {
          _this.allSaleCompanyList = res.Message
        }
      })
    },
    // 获取买方数据
    getBuyCompanyListfromdata() {
      const _this = this
      _this.sellCompanyListParas.CompanyType = 0
      $contractreq.GetSellCompanyList(_this.sellCompanyListParas).then(res => {
        if (res.RetCode === '0') {
          _this.allbuyCompanyList = res.Message
          _this.allCustomerCompanyList = res.Message
        }
      })
    },
    // 高级查询
    confirmserchadvance() {
      this.listadvancematerialParas.PageIndex = 1
      this.getContractMaterialList()
    },
    // 打开高级查询
    openadvance() {
      this.listadvancematerialParas.IsAdvancedSearch = 1
      this.AdvancedsearchVisibel = true
    },
    closeadvance() {
      this.listadvancematerialParas.IsAdvancedSearch = 0
    },
    listhandleCurrentChange(val) {
      this.listadvancematerialParas.PageIndex = val
      this.getContractMaterialList()
    },
    // 查询列表信息
    getContractMaterialList() {
      const _this = this
      _this.fullscreenLoading = true
      _this.AdvancedsearchVisibel = false
      _this.listadvancematerialParas.ContractType = _this.contractType
      $contractreq.GetContractMaterialList(_this.listadvancematerialParas).then((res) => {
        console.log(res)
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.tableData = res.Message
          _this.tableTotalSize = res.Total
        }
        if (res.RetCode === '0' && res.FieldStr) {
          if (JSON.parse(res.FieldStr).length > 0 && this.$refs.FieldManagement) {
            this.$nextTick(() => {
              this.tableListJson[ this.$refs.FieldManagement.name ] = JSON.parse(res.FieldStr)
            })
          }
        }
      })
    },
    // 更改合约查询类型
    changeType(_type) {
      this.contractType = _type
      this.listadvancematerialParas.PageIndex = 1
      this.getContractMaterialList()
    },
    // 表格选中的数据
    mateiralhandleSelectionChange(val) {
      // this.checkedMateialList = val
      this.checkedMaterial = []
      if (val) {
        val.forEach((ele) => {
          this.checkedMaterial.push(ele.IDX)
        })
      }
    },
    openUpdatePrice() {
      const _this = this
      if (!_this.checkedMaterial || _this.checkedMaterial.length < 1) {
        _this.$message('请至少选择一条明细改价')
        return false
      }
      _this.updatepriceVisibel = true
    },
    // 确认改价
    comfirmUpatePrice() {
      const _this = this
      if (_this.updatepriceInfo.type === 1 && !_this.updatepriceInfo.precent) {
        _this.$message('请填写比例')
        return false
      }
      if (_this.updatepriceInfo.type === 2 && !_this.updatepriceInfo.price) {
        _this.$message('请填写价格')
        return false
      }
      _this.$confirm('确认改价?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.UpatePrice()
      }).catch(() => {
      })
    },
    UpatePrice() {
      const _this = this
      _this.fullscreenLoading = true
      if (_this.updatepriceInfo.EditType === 1) {
        _this.updatepriceInfo.value = _this.updatepriceInfo.precent
      }
      if (_this.updatepriceInfo.EditType === 2) {
        _this.updatepriceInfo.value = _this.updatepriceInfo.price
      }
      // _this.updatepriceInfo.ContactID = _this.contractInfo.IDX
      _this.updatepriceInfo.IdxList = _this.checkedMaterial.join(',')
      $contractreq.SaveContractPrice(_this.updatepriceInfo).then((res) => {
        console.log(res)
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getContractMaterialList()
          _this.$message('操作成功')
          _this.updatepriceVisibel = false
        } else {
          _this.$message(res.Message)
        }
      })
    },
    // 单个改价
    UpateonePrice(row) {
      const _this = this
      _this.fullscreenLoading = true
      _this.updatepriceInfo.EditType = 2
      _this.updatepriceInfo.value = row.LatestAmount
      _this.updatepriceInfo.ContactID = row.ContactID
      _this.updatepriceInfo.IdxList = row.IDX
      $contractreq.SaveContractPrice(_this.updatepriceInfo).then((res) => {
        console.log(res)
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          row.isEdit = !row.isEdit
          _this.getContractMaterialList()
          _this.$message('操作成功')
        } else {
          _this.$message(res.Message)
        }
      })
    },
    // ================================================= 选择人员 begin  =================================================
    // ================================================= 选择人员 begin ==================================================
    selectUseractiveNamehandleClick(val) {
      console.log(val)
    },
    // 初始化选择人员数据
    oprenSelectContractUser() {
      const _this = this
      if (!_this.checkedMaterial || _this.checkedMaterial.length < 1) {
        _this.$message('请至少选择一条数据')
        return false
      }
      _this.getSelectUserQueryListOfDept()
      _this.seletUserVisible = true
    },
    // 获取选择人员时 ，成员列表
    getSelectUserQueryListOfDept() {
      const _this = this
      _this.fullscreenLoading = true
      _this.connuserlist = []
      $userRequest.SelectUserQueryListOfDept().then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.connuserlist = res.Message
        }
      })
    },
    // 过滤树形数据
    alluserfilterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 选中人员
    checkeduser(ischecked, val, item) {
      const _this = this
      if (ischecked) {
        if (_this.checkedUserInfo) {
          item.ischecked = !ischecked
          _this.$message('只能选择一个人员！')
          return false
        }
        if (item.children) {
          item.ischecked = !ischecked
          _this.$message('只能选择一个人员！')
          return false
        } else {
          _this.checkedUserInfo = item
          _this.checkedContractOptionUserID = item.IDX
          _this.checkedContractOptionUserName = item.UserName
          _this.checkedContractOptionUserNickName = item.GroupName
        }
      } else {
        _this.checkedUserInfo = null
        _this.checkedContractOptionUserID = null
        _this.checkedContractOptionUserName = null
        _this.checkedContractOptionUserNickName = null
      }
    },
    // 确认选中人员 _type 1：列表选择业务员 2复制合约选择业务员
    doconnuser() {
      const _this = this
      if (!_this.checkedUserInfo) {
        _this.$message('请选中人员！')
        return false
      }
      _this.dealContractListOperater()
      // const _this = this
    },
    // 合约明细修改业务员
    dealContractListOperater() {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.EditContractDeatilSales({
        userName: _this.checkedUserInfo.UserName,
        ContractDeatilId: _this.checkedMaterial.join(','),
        salesName: _this.checkedUserInfo.GroupName,
        groupId: null,
        groupName: null
      }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getContractMaterialList()
          _this.$message('操作成功')
          _this.seletUserVisible = false
        } else {
          _this.$message('操作失败')
        }
      })
    },
    // ================================================= 选择人员 end  =================================================
    // ================================================= 选择人员 end ==================================================
    // 合约状态 筛选
    filterTag(value, row) {
      return row.Status === value
    },
    getInfo(index) {
      switch (index) {
        case 0:
          return { info: '待上架', type: 'primary' }
        case 1:
          return { info: '已上架', type: 'primary' }
        case 2:
          return { info: '已下架', type: 'success' }
        case 3:
          return { info: '合约冻结', type: 'info' }
        case 4:
          return { info: '物料冻结', type: 'info' }
        case -2:
          return { info: '待审核', type: 'info' }
        case -3:
          return { info: '已驳回', type: 'info' }
        default:
          return { info: '暂无', type: 'warning' }
      }
    },
    // 打开高级搜索框
    OpenAdvanSearch() {
    }
  } // methods ends
}
export default contractMaterialList
