<template>
  <div class="">
    <PageHeader :border="false" title="物料合约清单" center />
    <div v-loading.fullscreen.lock="fullscreenLoading" class="p-lr-20">
      <div class="searchView  flex a-center m-bottom-10 j-between">
        <el-popover
          v-model="contractTypevisible"
          class="OrderDropDown"
          placement="bottom"
          trigger="hover"
          :width="100"
        >
          <div class="flex column ">
            <div v-if="$minCommon.checkMeau('wlhyqdcghy', userBottons)" :class="contractType === 2? typecolor : typestyle" @click="changeType(2)">采购合约</div>
            <div v-if="$minCommon.checkMeau('wlhyqdxshy', userBottons)" :class="contractType === 1? typecolor : typestyle" @click="changeType(1)">销售合约</div>
          </div>
          <div slot="reference" style="width:80px" class="out white bg p-lr-10 min-border-right flex a-center m-right-10 pointer" @click="contractTypevisible = !contractTypevisible">
            <div v-if="contractType === 1" class="cursor">销售合约</div>
            <div v-if="contractType === 2" class="cursor">采购合约</div>
            <i class="el-icon-caret-bottom" />
          </div>
        </el-popover>
        <div class="flex" @click="() => $refs.FieldManagement.openSetFieldManagement()">
          <div class="out cursor p-lr-10 p-tb-5 min-border flex a-center f14 m-right-10">
            <i class="el-icon-setting f15 p-right-5" />   字段管理
          </div>
        </div>
        <div class="flex SearchBox_30" style="flex:1">
          <el-input v-model="listadvancematerialParas.KeyWords" placeholder="可通过合约名称、甲方、乙方、送达方搜索" class="rule-input-edit SearchBox_30">
            <template slot="prepend">
              <div class="cursor" @click="openadvance">
                <i class="el-icon-s-operation" />高级搜索</div></template>
            <div slot="append" class="cursor" @click="getContractMaterialList()">搜  索</div>
          </el-input>
        </div>

        <div class="out p-left-10  flex a-center f-1">
          <!--   v-if="$minCommon.checkMeau('xsjebbexport', userBottons)"  -->
          <div class="out white  bg p-lr-10 p-tb-5 min-border-right flex a-center f14 m-lr-10  pointer ModelBttton-white-30" @click="exportExel">
            <i class="el-icon-upload2 f15  p-right-10 " />   导出
          </div>
          <el-button v-if="contractType === 1 && $minCommon.checkMeau('wlhyqdchangeuser', userBottons)" class="ModelBttton-white-30" round @click="oprenSelectContractUser"><i class="el-icon-refresh m-right-5" />更换业务员</el-button>
          <el-button v-if="contractType === 1 && $minCommon.checkMeau('wlhyqdchagneprice', userBottons)" class="ModelBttton-white-30" round @click="openUpdatePrice"><i class="el-icon-refresh m-right-5" />批量改价</el-button>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        v-loading="tableDataloading"
        :header-cell-style="{background:'rgb(247,248,252)'}"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 315px)"
        row-key="id"
        border
        @selection-change="mateiralhandleSelectionChange"
      >
        <af-table-column
          type="selection"
          width="40"
        />
        <template v-if="contractType === 1">
          <template v-for="(item,index) in tableListJson.contractMaterialList1">
            <el-table-column
              v-if="item.isChecked && item.prop !== 'Deadline' && item.prop !== 'TaxRate' && item.prop !== 'LatestAmount' && item.prop !== 'MaterialTypeName'  && item.prop !== 'StatusShow'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            />
            <el-table-column
              v-if="item.isChecked && item.prop === 'StatusShow' "
              :key="index"
              :label="item.label"
              :prop="item.prop"
              filter-placement="bottom-end"
              :filter-method="filterTag"
              :filters="[{text: '待上架', value: 0},{text: '已上架', value: 1}, {text: '已下架', value: 2}, {text: '合约冻结', value: 3}, {text: '物料冻结', value: 4}, {text: '待审核', value: -2}, {text: '已驳回', value: -3}]"
            >
              <template slot-scope="scope">
                <span :class="[2,3,4].indexOf(scope.row.Status) > -1 ? 'statusColor' : ''">{{ scope.row.StatusShow }}</span>
              </template>
            </el-table-column>
            <!-- 效期 -->
            <el-table-column
              v-if="item.isChecked && item.prop === 'Deadline' "
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ $minCommon.setDateFormate(scope.row.Deadline) }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="item.isChecked && item.prop === 'MaterialTypeName' "
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 物料类型 -->
              <template slot-scope="scope">
                {{ scope.row.MaterialTypeName }} - {{ scope.row.MaterialSubTypeName }}
              </template>
            </el-table-column>

            <el-table-column
              v-if="item.isChecked && item.prop === 'LatestAmount' "
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 价格 -->
              <template slot-scope="scope">
                <el-input v-if="scope.row.isEdit" v-model="scope.row.LatestAmount" />
                <span v-if="!scope.row.isEdit">{{ scope.row.LatestAmount }}</span>
              </template>
            </el-table-column>

            <el-table-column
              v-if="item.isChecked && item.prop === 'TaxRate' "
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 税率(%) -->
              <template slot-scope="scope">
                <span>{{ scope.row.TaxRate*100 }}</span>
              </template>
            </el-table-column>
            <!-- 合约类型 -->
            <!-- <el-table-column
              v-if="item.isChecked && item.prop === 'ContractType' "
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <template v-if="item.isChecked && item.prop === 'ContractType'" slot-scope="scope">
                <span v-if="scope.row.ContractType === 1">采购合约</span>
                <span v-if="scope.row.ContractType === 0">销售合约</span>
              </template>
            </el-table-column> -->
          </template>
        </template>
        <template v-if="contractType === 2">
          <template v-for="(item,index) in tableListJson.contractMaterialList2">
            <af-table-column
              v-if="item.isChecked && item.prop !== 'Deadline' && item.prop !== 'StatusShow'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            />
            <el-table-column
              v-if="item.isChecked && item.prop === 'StatusShow' "
              :key="index"
              :label="item.label"
              :prop="item.prop"
              filter-placement="bottom-end"
              :filter-method="filterTag"
              :filters="[{text: '待上架', value: 0},{text: '已上架', value: 1}, {text: '已下架', value: 2}, {text: '合约冻结', value: 3}, {text: '物料冻结', value: 4}, {text: '待审核', value: -2}, {text: '已驳回', value: -3}]"
            >
              <template slot-scope="scope">
                <span :class="[2,3,4].indexOf(scope.row.Status) > -1 ? 'statusColor' : ''">{{ scope.row.StatusShow }}</span>
              </template>
            </el-table-column>
            <!-- 效期 -->
            <el-table-column
              v-if="item.isChecked && item.prop === 'Deadline' "
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ $minCommon.setDateFormate(scope.row.Deadline) }}
              </template>
            </el-table-column>
          </template>
        </template>

        <af-table-column
          fixed="right"
          prop="options"
          label="操作"
          width="75"
        >
          <template slot-scope="scope">
            <div v-if="contractType === 1?true:false">
              <div v-if="!scope.row.isEdit && $minCommon.checkMeau('wlhyqdchagneprice', userBottons)">
                <i class="el-icon-edit p-right-10" />
                <span><el-link type="primary" :underline="false" @click="scope.row.isEdit = !scope.row.isEdit">改价</el-link></span>
              </div>
              <div v-if="scope.row.isEdit" class="m-tb-10">
                <i class="el-icon-tickets p-right-10" />
                <span><el-link type="primary" :underline="false" @click="UpateonePrice(scope.row)">保存</el-link></span>
              </div>
            </div>
            <span v-if="contractType === 2?true:false">无</span>
          </template>
        </af-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :total="tableTotalSize"
          :current-page="listadvancematerialParas.PageIndex"
          :page-size="listadvancematerialParas.PageSize"
          layout="prev, pager, next, jumper"
          @current-change="listhandleCurrentChange"
        />
      </div>
    </div>
    <!-- 合约高级搜索 -->
    <el-dialog class="DepartmentBox" title="合约高级搜索" :visible.sync="AdvancedsearchVisibel" width="800px" :close-on-click-modal="false" @closed="closeadvance">
      <el-button style="float:right" size="mini" plain @click="initadvance">清空搜索条件</el-button>
      <el-form label-position="right" label-width="100px" :inline="true" :model="listadvancematerialParas" size="mini" class="InfoBoxTwo3">
        <el-form-item label="创建时间段">
          <el-date-picker
            v-model="listadvancematerialParas.CreateStartTime"
            type="date"
            value-format="yyyy-MM-dd"
            style="width:calc((100% - 15px) / 2)"
          />
          -
          <el-date-picker
            v-model="listadvancematerialParas.CreateEndTime"
            type="date"
            value-format="yyyy-MM-dd"
            style="width:calc((100% - 15px) / 2)"
          />
        </el-form-item>
        <!-- <el-form-item  label="合约类型" style="width:48%">
            <el-select v-model="listadvancematerialParas.ContractType" placeholder="" filterable>
              <el-option  label="销售合约" :value=2 />
              <el-option  label="采购合约" :value=1 />
            </el-select>
            </el-form-item> -->
        <el-form-item label="合约编号">
          <el-input
            v-model="listadvancematerialParas.ContractCode"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="合约名称">
          <el-input
            v-model="listadvancematerialParas.ContractName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="物料编号">
          <el-input
            v-model="listadvancematerialParas.InvCode"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="物料名称">
          <el-input
            v-model="listadvancematerialParas.InvName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="品牌">
          <el-input
            v-model="listadvancematerialParas.brandname"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="买方">
          <!-- <el-select v-model="listadvancematerialParas.BuyCompanyID" placeholder="" filterable>
              <el-option  v-for="(item, index) in BuyCompanyList"  :key="index" :label="item.companyName" :value="item.companyID" />
            </el-select> -->
          <el-select
            v-model="listadvancematerialParas.BuyCompanyID"
            filterable
            clearable
            remote
            reserve-keyword
            placeholder="请选择买方名称"
            :remote-method="buyremoteMethod"
            :loading="selectBuyloading"
          >
            <el-option
              v-for="(item, index) in BuyCompanyList"
              :key="index"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="供应商">
          <!-- <el-select v-model="listadvancematerialParas.SellCompanyID" placeholder="" filterable>
              <el-option  v-for="(item, index) in SaleCompanyList"  :key="index" :label="item.companyName" :value="item.companyID" />
            </el-select> -->
          <el-select
            v-model="listadvancematerialParas.SellCompanyID"
            clearable
            filterable
            remote
            reserve-keyword
            placeholder="请选择甲方名称"
            :remote-method="sellremoteMethod"
            :loading="selectsellloading"
          >
            <el-option
              v-for="(item, index) in SaleCompanyList"
              :key="index"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="终端">
          <!-- <el-select v-model="listadvancematerialParas.SendCompanyID" placeholder="" filterable>
              <el-option  v-for="(item, index) in CustomerCompanyList"  :key="index" :label="item.companyName" :value="item.companyID" />
            </el-select> -->
          <el-select
            v-model="listadvancematerialParas.SendCompanyID"
            clearable
            filterable
            remote
            reserve-keyword
            placeholder="请选择甲方名称"
            :remote-method="sendremoteMethod"
            :loading="selectsendloading"
          >
            <el-option
              v-for="(item, index) in CustomerCompanyList"
              :key="index"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="注册证号">
          <el-input
            v-model="listadvancematerialParas.CertificateName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
      </el-form>
      <div class="caozuo p-tb-20 t_line_s m-top-10">
        <el-button @click="AdvancedsearchVisibel = false">取 消</el-button>
        <el-button type="primary" @click="confirmserchadvance">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择业务员 -->
    <el-dialog class="DepartmentBox" title="批量替换业务员" center :visible.sync="seletUserVisible" width="400px" :close-on-click-modal="false">
      <div class="rolebody">
        <el-tabs v-model="selectUseractiveName">
          <el-tab-pane label="成员" name="user" />
          <el-tab-pane v-if="isshow" label="部门" name="dept" />
          <el-tab-pane v-if="isshow" label="角色" name="role" />
        </el-tabs>
        <div v-if="selectUseractiveName === 'user'" class="selectuser">
          <el-input
            v-model="selectUserkeywords"
            size="small"
            placeholder="搜索成员"
            prefix-icon="el-icon-search"
          />
          <div class="selectUserTree">
            <el-tree
              ref="allusertree"
              :data="connuserlist"
              node-key="value"
              highlight-current
              :filter-node-method="alluserfilterNode"
            >
              <span slot-scope="{ node, data }" style="width:100%">
                <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
                <span class="deptnamespan">
                  {{ node.label }}</span>
                <!-- nodeType- 0：部门 1：员工 -->
                <span v-if="data.nodeType === 1" class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkeduser(data.ischecked,data.value,data)" /></span>
              </span>
            </el-tree>
          </div>
        </div>
        <div v-if="selectUseractiveName === 'dept'" class="selectuser">
          <el-tree
            ref="tree"
            :data="selectUserDeptData"
            node-key="value"
            highlight-current
          >
            <span slot-scope="{ node, data }" style="width:100%">
              <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
              <span class="deptnamespan">
                {{ node.label }}</span>
              <span class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkedDept(data.ischecked,data.value,data)" /></span>
            </span>
          </el-tree>
        </div>
        <div v-if="selectUseractiveName === 'role'" class="selectuser">
          <div v-for="(item, index) in selectUserroleData" :key="index" class="selectuserrole">
            <span class="deptnamespan">
              {{ item.name }}</span>
            <span class="deptcountspan"><el-checkbox v-model="item.ischecked" /></span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="seletUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="doconnuser">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 改价 -->
    <el-dialog class="DepartmentBox" title="改价" :visible.sync="updatepriceVisibel" width="400px" :close-on-click-modal="false">
      <el-form label-position="right" label-width="90px" :model="updatepriceInfo" size="mini">
        <el-form-item label="改价方式" class=" is-required ">
          <el-radio v-model="updatepriceInfo.EditType" :label="1">按比例</el-radio>
          <el-radio v-model="updatepriceInfo.EditType" :label="2">按固定价</el-radio>
        </el-form-item>
        <el-form-item v-if="updatepriceInfo.EditType === 1" label="设置比例" class=" is-required " prop="precent">
          <div class="flex">
            <el-input-number v-model="updatepriceInfo.precent" :min="0" :max="99999" /><span class="m-left-5">%</span>
          </div>
        </el-form-item>
        <el-form-item v-if="updatepriceInfo.EditType === 2" label="合约价格" class=" is-required " prop="price">
          <el-input-number v-model="updatepriceInfo.price" :min="0" :max="99999999" />
        </el-form-item>
      </el-form>
      <el-divider />
      <div class="caozuo p-bottom-20">
        <el-button @click="updatepriceVisibel = false">取 消</el-button>
        <el-button type="primary" @click="comfirmUpatePrice">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
    <!-- 字段搜索 -->
    <FieldManagement ref="FieldManagement" :select-type="contractType" action-name="GetContractMaterialList" :list="contractType === 1 ? tableListJson.contractMaterialList1 : tableListJson.contractMaterialList2" :name="contractType === 1 ? 'contractMaterialList1' :'contractMaterialList2'" :change-list-table="changeListTable" />
  </div>
</template>
<script>
import contractMaterialList from '@/minxin/contract/contractMaterialList.js'
export default contractMaterialList
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/main.scss';
@import '@/style/salesContract.scss';
</style>
<style>
.typecolor {
  color: #409EFF;
}
.el-popover {
  min-width: 80px;
}
.el-dialog--center .el-dialog__body {
  padding: 25px 25px 0px;
}
</style>
<style lang='scss'>
.salesContrac{
  position: relative;
  .allChilce{
      height: 25px;
      line-height: 25px;
      .active{
        color: #333;
      }
      .nomr{
         color: #ccc;
      }
  }
  .main_flex{
    // width: 100%;
    min-height: 400px;
    .left{
      width: 400px;
    }
    .right{
      flex: 1;
      min-width: 400px;
    }
  }
  .cropper-content {
    .cropper {
      //  min-width: 500px;
       width: 100%;
       height:  70vh;
    }
}
.absol{
  position: absolute;
  left: 0;
}
}
.test_pop_view{
  min-width: 60px;
}
</style>
