import { get, post } from './request'
export default {
  // 获取合约列表
  GetSalesContractList: data => post('/Contract/GetSalesContractList', data),
  // 获取供应商数据
  GetSellCompanyList: data => post('/Company/GetCompanyNameQueryListJson', data),
  // 创建合约
  SetSalesContractSave: data => post('/Contract/SetSalesContractSave', data),
  // 改价
  SaveContractPrice: data => post('/SaveContractPrice', data),
  // 查询合约详情
  GetContactDetailsQueryList: data => get('/GetContactDetailsQueryList', data),
  // 导出合约明细
  ExportContractDetails: data => post('/ExportContractDetails', data),
  // 上传附件
  UploadContractFile: data => post('/UploadContractFile', data),
  // 添加物料 的物料查询
  DetailsAddList: data => post('/Contract/DetailsAddList', data),
  // 合约修改业务员
  SaveSalesMan: data => post('/Contract/SaveSalesMan', data),
  // 合约明细修改业务员
  EditContractDeatilSales: data => post('/Contract/EditContractDeatilSales', data),
  // 合约明细上架下架
  DetailsStatusUpdate: data => post('/Contract/DetailsStatusUpdate', data),
  // 合约附件查看
  GetContractFileList: data => get('/GetContractFileList', data),
  // 合约物料列表清单
  GetContractMaterialList: data => post('/GetContractMaterialList', data),
  // 合约禁用启用
  UpdateContractStatus: data => post('/UpdateContractStatus', data),
  // 删除合约明细
  DeleteContractDetails: data => post('/DeleteContractDetails', data),
  // 获取合约科室
  GetGroupList: data => get('/GetGroupList', data),
  // 保存科室
  SaveContractRefGroup: data => post('/SaveContractRefGroup', data),
  // 下载合约zip
  DownloadFirstCampZip: data => post('/DownloadFirstCampZip', data),
  // 删除合约附件
  DeleteContractFile: data => post('/DeleteContractFile', data),
  // 保存合约
  ContractDetailsSave: data => post('/Contract/ContractDetailsSave', data),
  // 根据合约id获取合约明细
  GetContractDetailsByID: data => post('/GetContractDetailsByID', data),
  // 导出合约清单   api/GetContractMaterialList_Export
  GetContractMaterialList_Export: data => post('/GetContractMaterialList_Export', data)
}
