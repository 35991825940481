<template>
  <el-table
    ref="multipleTable"
    v-loading="tableDataloading"
    :header-cell-style="{background:'rgb(247,248,252)'}"
    :data="contractFileData"
    tooltip-effect="dark"
    show-header
    row-key="id"
    border
  >
    <el-table-column
      prop="FileName"
      label="文件名称"
    />
    <el-table-column
      prop="WriteNickName"
      label="上传人"
      show-overflow-tooltip
    />
    <!-- <el-table-column
        prop="Remark"
        label="备注"
        show-overflow-tooltip
      /> -->
    <el-table-column
      prop="UpdateTime"
      label="更新时间"
      show-overflow-tooltip
    />
    <el-table-column
      fixed="right"
      prop=""
      label="操作"
    >
      <template slot-scope="scope">
        <el-popover
          placement="left"
          trigger="hover"
          :width="100"
          popper-class="test_pop_view"
        >
          <div>
            <div class="m-tb-10">
              <i class="el-icon-view p-right-10" />
              <span><el-link type="primary" :underline="false" @click="lookFiles(scope.row.FileUrl)">查看</el-link></span>
            </div>
            <div class="m-tb-10">
              <i class="el-icon-view p-right-10" />
              <span><el-link type="primary" :underline="false" @click="confirmdeleteFiles(scope.row.IDX)">删除</el-link></span>
            </div>
          </div>
          <i slot="reference" class="el-icon-more" />
        </el-popover>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import $contractreq from '@/request/contractApi.js'
export default {
  name: 'ContractFilsList',
  props: {
    contractFileData: {
      type: Array,
      default: () => [
        {
          idx: 1,
          fileUrl: 'ffsafsda',
          filename: 'test001',
          uploadUser: 'phalange',
          remark: 'text',
          editTime: '2021-03-06'
        }
      ]
    },
    contractId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tableHeight: 'calc(100vh - 340px)',
      tableDataloading: false
    }
  },
  methods: {
    lookFiles(_url) {
      window.open(this.b2bstaticUrl + _url)
    },
    confirmdeleteFiles(_idx) {
      const _this = this
      _this.$confirm('确认删除附件吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.deleteFiles(_idx)
      }).catch(() => {
      })
    },
    deleteFiles(_idx) {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.DeleteContractFile({ IDX: _idx }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getContractFilesList(_this.contractId)
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败')
        }
      })
    },
    getContractFilesList(_idx) {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.GetContractFileList({ ContractID: _idx }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.contractFileData = res.Message
        }
      })
    }
  }
}
</script>
