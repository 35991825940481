const root = {
  data() {
    return {
      userBottons: []
    }
  },
  mounted() {
    this.getBottons(this.root_name)
  },
  methods: {
    // 获取页面按钮权限
    async getBottons(elementId) {
      const _this = this
      await this.$jurisdiction.GetUserButtonListByMenuElementID({ elementId: elementId }).then(res => {
        if (res.RetCode === '0') {
          _this.userBottons = res.Message
        } else {
          _this.$message.error(this.root_msg + '获取按钮权限报错：' + res.Message)
        //   console.log('企业首营获取按钮权限报错：' + res.RetMsg)
        }
      })
      this.$store.dispatch('index/setallpageLoading', false)
    }
  }
}
export default root
